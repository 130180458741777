import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HGroup, HGroupAdapter } from '../model/hgroup';
import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class HGroupService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private adapter: HGroupAdapter
  ) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Returns the object identified by id
   * @param id
   */
  public show(id: string): Observable<HGroup> {
    return this.sendGET(`${this.basePath}/hgroups/${id}`)
  }

  /**
   * Returns a single object HGroup. It is a singleton on the CMS API.
   */
  public index(): Observable<HGroup> {
    return this.sendGET(`${this.basePath}/hgroups`);
  }

  /**
   * Replaces an HGroup by _id
   * @param id
   * @param hGroup
   */
  public replace(id: string, hGroup: HGroup) {
    return this.sendPUT(`${this.basePath}/hgroups/${id}`, this.adapter.removeReadOnly(hGroup));
  }
}
