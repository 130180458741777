import { Injectable } from '@angular/core';
import { omit } from 'lodash-es'
import { Adapter } from '../adapter';
import { GROUP_PURPOSE } from './group';
import { Mongo } from './mongo';

/**
 * Adapter class for conversion to API models.
 */
@Injectable({
  providedIn: 'root',
})
export class HGroupAdapter implements Adapter<HGroup> {
  static READ_ONLY = ['_id', 'created_at', 'updated_at'];

  /**
   * Removes the read-only properties.
   * @param hGroupApi
   */
  removeReadOnly(hGroupApi: HGroupApi): HGroup {
    return omit(hGroupApi, HGroupAdapter.READ_ONLY);
  }
}

/**
 * HGroup model used for API PUT methods.
 */
export class HGroup {
  groupTree: Array<GroupNode>
}

/**
 * HGroup model with mongo properties obtained from API with GET method.
 */
export class HGroupApi extends Mongo {
  groupTree: Array<GroupNode>
}

export interface IGroupNode {
  address: string;
  name: string;
  purpose: GROUP_PURPOSE;
  gateway_address?: string;
  device_class_name?: string;
  children: Array<GroupNode>;
}

/**
 * Element (Node) of HGroup.groupTree.
 */
export class GroupNode implements IGroupNode {
  public address: string;
  public name: string;
  public purpose: GROUP_PURPOSE;
  public gateway_address?: string;
  public device_class_name?: string;
  public children: Array<GroupNode>;

  constructor(groupNode?: IGroupNode) {
    if (groupNode === undefined || groupNode === null) {
      this.address = '';
      this.name = '';
      this.purpose = GROUP_PURPOSE.cms;
      this.children = [];
    }
  }
}
