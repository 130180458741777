import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'

import { Configuration } from '../configuration'
import { GroupAdapter } from '../model/group';

import { BASE_PATH } from '../variables'
import { ApiService } from './api.service'

/**
 * Service for sending requests to the gateway groups endpoints
 * gateways/:address/device-classes/:deviceClassName/groups
 */
@Injectable()
export class GatewayGroupService extends ApiService {

  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private adapter: GroupAdapter
  ) {
    super(httpClient, basePath, configuration)
  }

  /**
   * Stores a gateway group (TALQ purpose).
   * @param gatewayAddress
   * @param deviceClassName
   * @param group
   */
  public storeByDeviceClass(gatewayAddress: string, deviceClassName: string, group) {
    return this.sendPOST(
      `${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/groups`,
      this.adapter.removeReadOnly(group)
    );
  }

  /**
   * Replaces a gateway group (TALQ purpose).
   * @param gatewayAddress
   * @param deviceClassName
   * @param groupAddress
   * @param group
   */
  public replaceByDeviceClass(gatewayAddress: string, deviceClassName: string, groupAddress: string, group) {
    return this.sendPUT(
      `${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/groups/${groupAddress}`,
      this.adapter.removeReadOnly(group)
    );
  }

  /**
   * Destroy a gateway group (TALQ purpose).
   * @param gatewayAddress
   * @param deviceClassName
   * @param groupAddress
   */
  public destroyByDeviceClass(gatewayAddress: string, deviceClassName: string, groupAddress: string) {
    return this.sendDELETE(`${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/groups/${groupAddress}`)
  }

  /**
   * Replace a TALQ group.
   * @param gatewayAddress
   * @param deviceClassName
   * @param groupAddress
   * @param deviceAddresses
   */
  public replaceDevicesTALQGroup(gatewayAddress: string, deviceClassName: string, groupAddress: string, deviceAddresses: Array<string>) {
    return this.sendPUT(
      `${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/groups/${groupAddress}/devices`,
      deviceAddresses
    );
  }
}
