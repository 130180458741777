import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { EVENT_TYPES, StickyAction } from '../utils/model/ui-change';

// Message severities from primeng https://www.primefaces.org/primeng/#/toast
const SEVERITIES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
}

@Injectable({ providedIn: 'root' })
export class SharedService {
  // Observable string sources
  private emitChangeSource = new Subject();
  // Other UI behaviour change source
  private uiChangeSource = new Subject();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Observable object streams, for the moment we only use strings, but in order to be able to play
  // with more complicated object, we prepare an object stream
  uiChangeEmitted$: Observable<any> = this.uiChangeSource.asObservable();

  constructor(
    private translate: TranslateService,
    private message: MessageService
  ) {
  }

  // Service message commands
  emitChange(change: string) {
    this.emitChangeSource.next(change);
  }

  private _emitUIChange(type: string, value: any) {
    this.uiChangeSource.next({ type, value });
  }

  public reachedBottom() {
    this._emitUIChange(EVENT_TYPES.SCROLL_BOTTOM_POSITION_REACHED, {});
  }

  public reachedTop() {
    this._emitUIChange(EVENT_TYPES.SCROLL_TOP_POSITION_REACHED, {});
  }

  public scrolling(scrollParams) {
    this._emitUIChange(EVENT_TYPES.SCROLL_SCROLLING, scrollParams);
  }

  public useStickyActions(actions: Array<StickyAction>) {
    this._emitUIChange(EVENT_TYPES.ADD_STICKY_ACTION, actions);
  }

  public resetUI() {
    this._emitUIChange(EVENT_TYPES.RESET, true);
  }

  public resetScroll() {
    this._emitUIChange(EVENT_TYPES.SCROLL_RESET, {});
  }

  public hideOverflow() {
    this._emitUIChange(EVENT_TYPES.SHOW_OVERFLOW, false);
  }

  public resetOverflow() {
    this._emitUIChange(EVENT_TYPES.SHOW_OVERFLOW, true);
  }

  /**
   * Translates the parameters and displays the toast messages.
   * @see https://www.primefaces.org/primeng/#/toast
   * @param severity - valid values are "success", "info", "warn" and "error"
   * @param header - text to translate into the toast "summary"
   * @param message - text to translate into the toast "detail"
   * @param params - translation variables
   * @param life - duration of a toast (default 3 seconds)
   * @private
   */
  private showMessage(severity: string, header: string, message?: string, params?: Object, life = 3000) {
    this.translate.get(header)
      .pipe(take(1))
      .subscribe({
        next: summary => {
          // if the message is provided, translate into toast detail
          if (message) {
            this.translate.get(message, params)
              .pipe(take(1))
              .subscribe({
                next: detail => {
                  this.message.add({ key: 'toast', severity, summary, detail, life });
                }
              });
          } else {
            // only header is provided for the toast summary
            this.message.add({ key: 'toast', severity, summary, detail: '', life });
          }
        }
      });
  }

  public showSuccess(header, message?, params?) {
    this.showMessage(SEVERITIES.SUCCESS, header, message, params);
  }

  public showWarn(header, message, params?) {
    this.showMessage(SEVERITIES.WARN, header, message, params);
  }

  /**
   * Show an error message, it should last longer than other kind of toast
   * To make user understand what happens
   * @param {string} header - translation key of the head
   * @param {string} message - translation key of the message
   * @param {Object} params - translation interpolation object
   * @param {number} life - duration of the toast expressed in milliseconds
   */
  public showError(header: string, message?: string, params?: Object, life = 5000) {
    this.showMessage(SEVERITIES.ERROR, header, message, params);
  }
}
