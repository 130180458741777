/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Alert } from '../model/alert';
import { AlertPatch } from '../model/alert';
import { EventPatch } from '../model/event-log';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class EventService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/events`, params)
  }

  /**
   * Updates an Event log.
   * eventPatch are a subset or all of the properties of Event model.
   * @param id
   * @param eventPatch
   */
  public update(id: string, eventPatch: EventPatch): Observable<Event> {
    return this.sendPATCH(`${this.basePath}/events/${id}`, eventPatch)
  }
}
