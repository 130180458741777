/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { ScopedClass } from '../../../interfaces/scoped-class.interface';
import { Configuration } from '../configuration';
import { Calendar } from '../model/calendar';
import { Address } from '../model/commonTypes';
import { ControlProgram } from '../model/controlProgram';
import { DeviceClass } from '../model/device-class';

import { Gateway } from '../model/gateway';
import { TalqGatewayService } from '../model/gateway-service/talq-gateway-service';
import { LampType } from '../model/lampType';
import { LoggerConfig } from '../model/logger-config';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class GatewayService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Returns all the gateways present in the CMS
   * @param params - the api query params if required
   */
  public index(params?: any): Observable<Array<Gateway>> {
    return this.sendGET(`${this.basePath}/gateways`, params)
  }

  /**
   * Returns the gateway identified by
   * @param address - talq address of the gateway
   */
  public show(address: string): Observable<Gateway> {
    return this.sendGET(`${this.basePath}/gateways/${address}`)
  }

  public update(address: string, body: Gateway) {
    return this.sendPATCH(`${this.basePath}/gateways/${address}`, body);
  }

  /**
   * Return the list of devices that were announced by the gateway
   * @param address - address of the gateway
   * @param params - the api query params if required
   */
  public getAnnouncedDevices(address: string, params?: any): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getAnnouncedDevices')
    return this.sendGETPaginated(`${this.basePath}/gateways/${address}/devices`, params)
  }

  /**
   * Deletes the device that belongs to a gateway
   * @param address - address of the gateway
   * @param deviceAddress - the target device to delete
   */
  public deleteAnnouncedDevice(address: string, deviceAddress: string): Observable<boolean> {
    this.throwErrorIfParamsIsNotProvided({ address, deviceAddress }, 'deleteAnnouncedDevice');
    return this.sendDELETE(`${this.basePath}/gateways/${address}/devices/${deviceAddress}`);
  }

  /**
   * Return the gateway class for the gateway identified by
   * @param address - talq address of the gateway
   */
  public getGatewayClass(address: string): Observable<DeviceClass> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getGatewayClass')
    return this.sendGET(`${this.basePath}/gateways/${address}/gateway-class`)
  }

  public getScopedGatewayClass(address: string): Observable<ScopedClass> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getGatewayClass')
    return this.sendGET(`${this.basePath}/gateways/${address}/gateway-class/scoped`)
  }

  /**
   * Create a gateway with
   * @param name - string
   * @param address - string
   */
  public createBaseGateway(name: string, address: string): Observable<Gateway> {
    const body = { name, address }
    return this.sendPOST(`${this.basePath}/gateways`, body)
  }

  /**
   * Ask the backend to generate an address for
   *    - gateway creation
   *    - gateway alteration
   */
  public generateAddress(): Observable<Address> {
    return this.sendPOST(`${this.basePath}/gateways/generate-address`)
  }

  /**
   * Returns the gateway services.
   * Can be filtered by optional parameters
   *
   * @param address
   * @param params
   */
  public showServices(address: string, params?: any): Observable<any> {
    return this.sendGET(`${this.basePath}/gateways/${address}/services`, params);
  }

  /**
   * Get lamp types associated to Gateway
   * @param address of gateway
   */
  public getLampTypes(address: string): Observable<Array<LampType>> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getLampTypes');
    return this.sendGET(`${this.basePath}/gateways/${address}/lamp-types`);
  }

  /**
   * Get calendars associated to Gateway
   * @param address of gateway
   * @param params of target device class (optional)
   */
  public getCalendars(address: string, params?: Object): Observable<Array<Calendar>> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getCalendars');
    return this.sendGET(`${this.basePath}/gateways/${address}/calendars`, params);
  }

  /**
   * Get control programs associated to Gateway
   * @param address of gateway
   * @param params filter parameters (optional)
   */
  public getControlPrograms(address: string, params?: Object): Observable<Array<ControlProgram>> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getControlPrograms');
    return this.sendGET(`${this.basePath}/control-programs?gateway_address=${address}`, params);
  }

  /**
   * Ask the backend to retrieve the loggable attributes based on DataCollectService with
   * the target device class
   * @param address
   * @param deviceClass
   * @param scope
   * @throws Error if `address` nor `deviceClass` are not provided
   */
  public getLoggableAttributesForClass(address: string, deviceClass: string, scope?: Array<string>): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ address, deviceClass }, 'getLoggableAttributesForClass');
    return this.sendGET(`${this.basePath}/gateways/${address}/device-classes/${deviceClass}/loggable-attributes`, { scope });
  }

  /**
   * Shows the gateway's logger configs for a device class
   * @param address
   * @param className
   */
  public showLoggerConfigs(address: string, className: string): Observable<Array<LoggerConfig>> {
    this.throwErrorIfParamsIsNotProvided({ address, className }, 'showLoggerConfigs');
    return this.sendGET(`${this.basePath}/gateways/${address}/device-classes/${className}/logger-configs`)
  }

  /**
   * Create a logger config for a gateway for a device class
   * @param address
   * @param className
   * @param payload
   */
  public storeLoggerConfigs(address: string, className: string, payload: LoggerConfig): Observable<LoggerConfig> {
    this.throwErrorIfParamsIsNotProvided({ address, className, payload }, 'storeLoggerConfigs');
    return this.sendPOST(`${this.basePath}/gateways/${address}/device-classes/${className}/logger-configs`, payload);
  }

  /**
   * Replace a logger config for a gateway for a device class
   * @param address
   * @param className
   * @param loggerAddress
   * @param payload
   */
  public replaceLoggerConfigs(address: string, className: string, loggerAddress: string, payload: LoggerConfig): Observable<LoggerConfig> {
    this.throwErrorIfParamsIsNotProvided({
      address,
      className,
      loggerAddress,
      payload
    }, 'replaceLoggerConfigs');
    return this.sendPUT(`${this.basePath}/gateways/${address}/device-classes/${className}/logger-configs/${loggerAddress}`, payload);
  }

  public storeLampType(address: string, lampType: LampType): Observable<LampType> {
    this.throwErrorIfParamsIsNotProvided({ address, lampType }, 'storeLampType');
    return this.sendPOST(`${this.basePath}/gateways/${address}/lamp-types`, lampType);
  }

  public replaceLampType(address: string, lampTypeAddress: string, lampType: LampType) {
    this.throwErrorIfParamsIsNotProvided({ address, lampTypeAddress, lampType }, 'storeLampType');
    return this.sendPUT(`${this.basePath}/gateways/${address}/lamp-types/${lampTypeAddress}`, lampType);
  }

  /**
   * Resynchronize the gateway.
   */
  public resynchronize(address: string, toKeep?: Array<string>): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'resynchronize')
    if (toKeep.length > 0) {
      return this.sendDELETE(`${this.basePath}/gateways/${address}/resynchronize`, { toKeep })
    } else {
      return this.sendDELETE(`${this.basePath}/gateways/${address}/resynchronize`)
    }
  }

  /**
   * Delete the gateway.
   */
  public delete(address: string, deleteLogsEventsAndAlerts: boolean): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ address, deleteLogsEventsAndAlerts }, 'delete')
    return this.sendDELETE(`${this.basePath}/gateways/${address}`, { deleteLogsEventsAndAlerts })
  }
}
