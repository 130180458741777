import { Injectable } from '@angular/core';
import { isEmpty, isNil, isArray } from 'lodash-es'
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxPermissionsObject } from 'ngx-permissions/lib/service/permissions.service';
import { UserService } from '../providers/adonis/api/user.service';

/**
 * PermissionLoaderService
 * The aim of this service is to wrap around the NgxPermissionService dependency
 *  and provide functions related to permission handling.
 */
@Injectable({ providedIn: 'root' })
export class PermissionLoaderService {
  public permissionReady: boolean;

  constructor(
    private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService) {

    /**
     * Listens to ngxPermissionsService (npm package) permissions$ event
     * when service has updated its permissions
     * - page reload (fetchAndLoadPermissions)
     * - after login (fetchAndLoadPermissions)
     * - after logout (flushPermissions)
     *
     * Makes available the flag telling if the permissions are successfully loaded
     */
    this.ngxPermissionsService.permissions$.subscribe({
      next: data => {
        this.permissionReady = !isEmpty(data);
      }
    });
  }

  /**
   * Fetches the user's permissions slugs from the CMS
   *  and loads them into NgxPermissionService.
   */
  public async fetchAndLoadPermissions(): Promise<boolean> {
    let permissions
    try {
      permissions = await this.userService.showPermissionsSlugs('me').toPromise();
      this.ngxPermissionsService.loadPermissions([...permissions, ...'*']);
    } catch (err) {
      console.error('[PermissionLoaderService:loadPermissions] Could not load permissions', err);
    }

    this.permissionReady = !isEmpty(permissions);
    return this.permissionReady;
  }

  /**
   * Checks that the current user has the permission passed as parameter.
   * Permission parameter can be either one slug or an array of slugs.
   * Returns true only if all permissions exist for the slug(s) in the parameter.
   * @param permission - slug or comma separated slugs
   */
  public hasPermission(permission: string | Array<string>): boolean {
    if (isEmpty(permission) || isNil(permission)) {
      return false;
    }
    let hasPermission = true;
    if (isArray(permission)) {
      // return false at the first permission that is not found
      for (let i = 0; i < permission.length; i++) {
        if (isNil(this.ngxPermissionsService.getPermission(permission[i].trim()))) {
          hasPermission = false;
          // break the for loop as soon as one permission is not found
          break;
        }
      }
    } else {
      const p = this.ngxPermissionsService.getPermission(permission.toString());
      if (isNil(p)) {
        hasPermission = false;
      }
    }
    return hasPermission;
  }

  /**
   * Returns the user's permissions.
   */
  public getPermissions(): NgxPermissionsObject {
    return this.ngxPermissionsService.getPermissions();
  }

  /**
   * Removes all permissions from the ngxPermissionService's memory.
   */
  public flushPermissions(): void {
    this.ngxPermissionsService.flushPermissions();
  }
}
