/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Id } from '../model/commonTypes';
import { Role } from '../model/rbac/role';
import { User } from '../model/user/user';
import { UserToken } from '../model/user/user-token';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';

@Injectable()
export class UserService extends ApiService {

  /** See ApiServe.paramsToOmit.
   * User service allows '_id' field to be sent, since it is used to
   * query users in the database.
   */
  // protected fieldsToOmit = this.fieldsToOmit.filter(p => p !== '_id')

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.fieldsToOmit = this.fieldsToOmit.filter(p => p !== '_id')
  }

  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/users`, params)
  }

  public store(user: User): Observable<User> {
    return this.sendPOST(`${this.basePath}/users`, user)
  }

  public show(userId: string): Observable<User> {
    return this.sendGET(`${this.basePath}/users/${userId}`)
  }

  public indexRoles(userId: string): Observable<Role> {
    return this.sendGET(`${this.basePath}/users/${userId}/roles`)
  }

  public isUserEditable(userId: string): Observable<boolean> {
    return this.sendGET(`${this.basePath}/users/${userId}/editable`)
  }

  public destroy(userId: string): Observable<any> {
    return this.sendDELETE(`${this.basePath}/users/${userId}`)
  }

  public update(userId: string, user: User): Observable<any> {
    return this.sendPATCH(`${this.basePath}/users/${userId}`, user)
  }

  public login(email: string, password: string): Observable<UserToken> {
    this.throwErrorIfParamsIsNotProvided({ email, password }, 'login');
    return this.sendPOST(`${this.basePath}/users/login`, { email, password });
  }

  public logout(): Observable<boolean> {
    return this.sendGET(`${this.basePath}/users/me/logout`);
  }

  public requestPasswordReset(email: string): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ email }, 'requestPasswordReset');
    return this.sendPOST(`${this.basePath}/users/request-reset-password`, { email });
  }

  public resetPassword(password: string, token: string): Observable<User> {
    this.throwErrorIfParamsIsNotProvided({ password, token }, 'resetPassword');
    return this.sendPOST(`${this.basePath}/users/reset-password`, { password, token });
  }

  public showPermissionsSlugs(userId: string): Observable<Array<string>> {
    this.throwErrorIfParamsIsNotProvided({ userId }, 'showPermissionsSlugs');
    return this.sendGET(`${this.basePath}/users/${userId}/permissions-slugs`);
  }

  public indexAlertConfigs(userId: string, params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/users/${userId}/alert-configs`, params)
  }

  public replaceAlertConfigs(userId: string, alertConfigIds: Array<Id>): Observable<Role> {
    return this.sendPUT(`${this.basePath}/users/${userId}/alert-configs`, alertConfigIds)
  }
}

