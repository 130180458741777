import { HttpErrorResponse } from '@angular/common/http';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { _mark_ } from '../../global';

@Injectable()
export class OnlineHttpInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService,
              private translate: TranslateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const serverStatusReq = req.clone();
    return next.handle(serverStatusReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // ERR_CONNECTION_REFUSED
        if (error.status === 0) {
          Promise
            .all([
              this.translate.get(_mark_('CONNECTION_REFUSED')).toPromise(),
              this.translate.get(_mark_('SERVER_NOT_REACHABLE')).toPromise()
            ])
            .then(translations => {
              this.messageService.clear();
              this.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: translations[0],
                detail: translations[1]
              });
            });
        }

        return throwError(error);
      })
    );
  }
}
