/**
 * infomir-backend-cms
 * Base class of service that will call CMS rest endpoints for front end route
 * All other services must extends from this class to reuse simpler endpoint calls
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { isArray, isNull, isObject, isUndefined, omit, set } from 'lodash-es';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { BASE_PATH } from '../variables';


@Injectable()
export class ApiService {

  /** Never send these these fields in a request body to the CMS */
  protected _fieldsToOmit = ['_id', 'created_at', 'updated_at', 'deleted_at']

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  private buildHeaders() {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return headers;
  }

  /**
   * Build the options for each type of requests. Will add
   * - headers
   * - withCredential
   * - params - optional, only if `options` is provided
   * to the queryOptions
   * @param options
   */
  private buildOptions(options?: any) {
    const queryOptions = {
      headers: this.buildHeaders(),
      withCredentials: this.configuration.withCredentials
    };
    if (!this.isParamNullOrUndefined(options)) {
      set(queryOptions, 'params', this.buildQueryParameters(options));
    }

    return queryOptions;
  }

  /**
   * Builds the query parameters as provided
   * @param paramsAsObject - {Object}
   */
  protected buildQueryParameters(paramsAsObject) {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    Object
      .keys(paramsAsObject)
      // Loop through all properties and update the query parameters
      .forEach(paramName => {
        const value = paramsAsObject[paramName];
        const toSet = value instanceof Date ? value.toISOString() : value;
        queryParameters = queryParameters.set(paramName, `${toSet}`);
      })

    return queryParameters;
  }

  /**
   * Check whether a parameter is null or undefined
   * @param value - any
   * @return boolean - True if value is null or undefined
   */
  protected isParamNullOrUndefined(value) {
    return isNull(value) || isUndefined(value);
  }

  /**
   * Returns the body without fields in this.fieldsToOmit
   * @param body - The request body
   */
  protected filterBody(body) {
    return isArray(body)
      ? body.map(bodyItem => {
        if (isObject(bodyItem)) {
          return omit(bodyItem, this._fieldsToOmit)
        }
        return bodyItem
      })
      : omit(body, this._fieldsToOmit);
  }

  /**
   * Will check all required parameter for any extend request calls and throw an error is one of the
   * required parameters is missing. Null or undefined verification
   * @param requiredParams {Object}
   * @param methodName {string}
   */
  protected throwErrorIfParamsIsNotProvided(requiredParams, methodName) {
    Object
      .keys(requiredParams)
      .forEach(paramName => {
        if (this.isParamNullOrUndefined(requiredParams[paramName])) {
          const errorMessage = `Required parameter '${paramName}' was null or undefined when calling ${methodName}`;
          throw new Error(`[${this.constructor.name}]: ${errorMessage}`);
        }
      });
  }

  /**
   * Send a POST request to `url` with `body` payload
   * @param url
   * @param body - optional
   */
  protected sendPOST(url: string, body?: any): Observable<any> {
    const filteredBody = this.filterBody(body)
    return this.httpClient.post<any>(url, filteredBody, this.buildOptions());
  }

  /**
   * Send a GET request to `url`
   * @param url
   * @param {Object} params - extra params that will be added to the request as query params - optional
   */
  protected sendGET(url: string, params?: any): Observable<any> {
    return this.httpClient.get<any>(url, this.buildOptions(params));
  }

  protected sendGETPaginated(url: string, params?: any): Observable<any> {
    const innerParams = { ...this.buildOptions(params), observe: 'response' as 'body' };
    return this.httpClient.get<any>(url, innerParams);
  }

  /**
   * Send a PATCH request to `url` with `body` payload
   * @param url
   * @param body
   */
  protected sendPATCH(url: string, body: any): Observable<any> {
    const filteredBody = this.filterBody(body)
    return this.httpClient.patch<any>(url, filteredBody, this.buildOptions());
  }

  /**
   * Send a PUT request to `url` with `body` payload
   * @param url
   * @param body
   */
  protected sendPUT(url: string, body: any): Observable<any> {
    const filteredBody = this.filterBody(body)
    return this.httpClient.put<any>(url, filteredBody, this.buildOptions());
  }

  /**
   * Send a DELETE request to `url`
   * @param url
   * @param params
   */
  protected sendDELETE(url: string, params?: any): Observable<any> {
    return this.httpClient.delete<any>(url, this.buildOptions(params));
  }

  get fieldsToOmit() {
    return this._fieldsToOmit;
  }

  set fieldsToOmit(fieldsToOmit: string[]) {
    this._fieldsToOmit = fieldsToOmit;
  }
}
