import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AlertConfigService } from './api/alert-config.service';
import { AlertService } from './api/alert.service';
import { ApiTraceService } from './api/api-trace.service';
import { AppLogService } from './api/app-log.service';
import { CalendarService } from './api/calendar.service';
import { ControlProgramService } from './api/control-program.service';
import { DataLogService } from './api/data-log.service';
import { DeviceClassService } from './api/device-class.service';
import { DeviceService } from './api/device.service';
import { EventService } from './api/event.service';
import { GatewayGroupService } from './api/gateway-group.service';
import { GatewayService } from './api/gateway.service';
import { GroupService } from './api/group.service';
import { HGroupService } from './api/hgroup.service';
import { LampTypeService } from './api/lamp-type.service';
import { LightControlService } from './api/light-control.service';
import { LoggerConfigService } from './api/logger-config.service';
import { MailConnectorService } from './api/mail-connector.service';
import { OnDemandReadService } from './api/on-demand-read.service';
import { PermissionService } from './api/permission.service';
import { RoleService } from './api/role.service';
import { SettingService } from './api/setting.service';
import { UserService } from './api/user.service';
import { Configuration } from './configuration';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    AlertService,
    AlertConfigService,
    ApiTraceService,
    AppLogService,
    CalendarService,
    ControlProgramService,
    DataLogService,
    DeviceService,
    DeviceClassService,
    EventService,
    GatewayService,
    GroupService,
    GatewayGroupService,
    HGroupService,
    LampTypeService,
    LightControlService,
    LoggerConfigService,
    MailConnectorService,
    OnDemandReadService,
    PermissionService,
    RoleService,
    SettingService,
    UserService
  ]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import your base AppModule only.');
    }
  }
}
