import { Injectable } from '@angular/core';
import { get, omit, pick, cloneDeep } from 'lodash-es'
import {
  commandExpToDate,
  TargetCommandSpa,
  TargetCommandDto
} from '../../../services/payload-builder/light-command.builder';
import { Adapter } from '../adapter';
import { IdAddressName } from './commonTypes';
import { Mongo } from './mongo';

@Injectable({
  providedIn: 'root',
})
export class GroupAdapter implements Adapter<Group> {
  static readonly READ_ONLY = ['_id', 'created_at', 'updated_at', 'gateway_address', 'device_class_name'];

  removeReadOnly(group: Group): Group {
    return omit(group, GroupAdapter.READ_ONLY)
  }

  /**
   * Clones the object group and shapes it according to its "purpose" property.
   * Object with purpose "override" will have "targetCommand" property.
   * Object with purpose loggerConfig, calendar, or lampType will have "entity" property.
   * Object with purpose "cms" or "unknown" will only have "name" property.
   * Devices are converted to resource references.
   * @param group
   */
  toPut(group: GroupSpa): GroupDto {
    const cloned: GroupSpa = cloneDeep(group);
    let grpDto: GroupDto = { name: '', members: [] }; // name is always mandatory
    switch (group.purpose) {
      case GROUP_PURPOSE.cms: {
        grpDto = pick(cloned, ['name']);
        break;
      }
      case GROUP_PURPOSE.unknown: {
        grpDto = pick(cloned, ['name']);
        break;
      }
      case GROUP_PURPOSE.override: {
        grpDto = pick(cloned, ['name']);
        if (cloned?.targetCommand?.state?.value) {
          grpDto.targetCommand = get(cloned, 'targetCommand');
          grpDto.targetCommand.expiration = cloned.targetCommand.expiration.toISOString();
        }
        break;
      }
      case GROUP_PURPOSE.loggerConfig: {
        grpDto = pick(cloned, ['name']);
        if (cloned?.entity?.address) {
          grpDto.entity = cloned.entity;
        }
        break;
      }
      case GROUP_PURPOSE.calendar: {
        grpDto = pick(cloned, ['name']);
        if (cloned?.entity?.address) {
          grpDto.entity = cloned.entity
        }
        break;
      }
      case GROUP_PURPOSE.lampType: {
        grpDto = pick(cloned, ['name']);
        if (cloned?.entity?.address) {
          grpDto.entity = cloned.entity
        }
        break;
      }
      default: {
        throw new Error('[GroupAdapter::toPut] Unknown group purpose ' + group.purpose);
      }
    }
    grpDto.members = this.devicesToResourceReferences(cloned.members)
    return grpDto;
  }

  /**
   * Clones the object group and shapes it according to its property "purpose".
   * Object with purpose "cms" will have "name" and "purpose".
   * Object with purpose "unknown" will have additional "gateway_address" and "device_class_name".
   * Object with purpose "override" will have additional "targetCommand".
   * Object with purpose "loggerConfig", "calendar" or "lampType" will have "entity" instead of "targetCommand".
   * Devices are converted to resource references.
   * @param group
   */
  toPost(group: GroupSpa): GroupDto {
    const cloned = cloneDeep(group);
    let grpDto: GroupDto = { name: '', members: [] }; // name is always required
    switch (group.purpose) {
      case GROUP_PURPOSE.cms: {
        grpDto = pick(cloned, ['name', 'purpose']);
        break;
      }
      case GROUP_PURPOSE.unknown: {
        grpDto = pick(cloned, ['name', 'purpose', 'gateway_address', 'device_class_name']);
        break;
      }
      case GROUP_PURPOSE.override: {
        grpDto = pick(cloned, ['name', 'purpose', 'gateway_address', 'device_class_name']);
        if (cloned?.targetCommand?.state?.value) {
          grpDto.targetCommand = get(cloned, 'targetCommand');
          grpDto.targetCommand.expiration = cloned.targetCommand.expiration.toISOString();
        }
        break;
      }
      case GROUP_PURPOSE.loggerConfig: {
        grpDto = pick(cloned, ['name', 'purpose', 'gateway_address', 'device_class_name']);
        if (cloned?.entity?.address) {
          grpDto.entity = cloned.entity
        }
        break;
      }
      case GROUP_PURPOSE.calendar: {
        grpDto = pick(cloned, ['name', 'purpose', 'gateway_address', 'device_class_name']);
        if (cloned?.entity?.address) {
          grpDto.entity = cloned.entity
        }
        break;
      }
      case GROUP_PURPOSE.lampType: {
        grpDto = pick(cloned, ['name', 'purpose', 'gateway_address', 'device_class_name']);
        if (cloned?.entity?.address) {
          grpDto.entity = cloned.entity
        }
        break;
      }
      default: {
        throw new Error('[GroupAdapter::toPost] Unknown group purpose ' + group.purpose);
      }
    }
    grpDto.members = this.devicesToResourceReferences(cloned.members)
    return grpDto;
  }

  /**
   * Clones the object Group from cms api and converts it to an object for SPA.
   * Converts the expiration date of targetCommand from iso string to Date object.
   * Omits the group members.
   * @param group
   */
  toSpa(group: GroupDto): GroupSpa {
    const groupSpa = cloneDeep(group);
    if (group.targetCommand) {
      commandExpToDate(groupSpa.targetCommand);
    }
    return omit(groupSpa, ['members']);
  }

  /**
   * Converts an array of short objects devices into a resource reference.
   * @param devicesShort
   */
  devicesToResourceReferences(devicesShort: Array<IdAddressName>): Array<ResourceReference> {
    return devicesShort.map(deviceShort => {
      return { resource: 'devices', address: deviceShort.address }
    })
  }
}

/**
 * Generic interface, not exported.
 * It is extended by GroupSpa or GroupDto.
 */
interface Group extends Mongo {
  address?: string;
  name: string;
  purpose?: GROUP_PURPOSE;
  gateway_address?: string;
  device_class_name?: string;
  entity?: ResourceReference;
}

/**
 * Group Spa uses the TargetCommandSpa with expiration as Date.
 */
export interface GroupSpa extends Group {
  members: Array<IdAddressName>;
  targetCommand?: TargetCommandSpa;
}

/**
 * Group Spa uses the TargetCommandSpa with expiration as ISO string.
 */
export interface GroupDto extends Group {
  members: Array<ResourceReference>;
  targetCommand?: TargetCommandDto;
}

/**
 * Resource Reference interface used for groups with "entity" property. See TALQ Json schema.
 */
export interface ResourceReference {
  resource: string;
  address: string;
}

/**
 * Group from CMS API for the list of groups with the number of devices.
 */
export interface GroupWithDevicesCount extends GroupDto {
  devicesCount: number;
}

/**
 * Group purposes according to TALQ and an additional "cms" purpose.
 */
export enum GROUP_PURPOSE {
  cms = 'cms',
  unknown = 'unknown',
  override = 'override',
  calendar = 'calendar',
  loggerConfig = 'loggerConfig',
  lampType = 'lampType'
}
