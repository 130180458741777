import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './providers/adonis/api.module';
import { Configuration } from './providers/adonis/configuration';
import { OnlineHttpInterceptor } from './providers/interceptors/online-http-interceptor';
import { TokenInterceptor } from './providers/interceptors/token.interceptor';
import { VersionInterceptor } from './providers/interceptors/version.interceptor';
import { AuthService } from './services/authService/auth.service';
import { PermissionLoaderService } from './services/permission-loader.service';
import { MultiTranslateHttpLoader } from './services/translations/multi-translate-http-loader';

// required for AOT compilation
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/devices-', suffix: '.json' },
    { prefix: './assets/i18n/permissions-', suffix: '.json' }
  ])
}

export const apiConfig: Configuration = new Configuration();

export function getApiConfig() {
  apiConfig.basePath = `${environment.domain}${environment.apiPath}`;
  apiConfig.apiKeys = {};
  apiConfig.apiVersion = environment.apiVersion;
  return apiConfig;
}

/**
 * Returns an app initialization function that fetches all the user's
 * permissions from the backend and loads them in NgxPermissionsService.
 * @see https://angular.io/api/core/APP_INITIALIZER
 */
export function initializePermissions(
  injector: Injector,
  permissionLoaderService: PermissionLoaderService
) {
  return async () => {
    const authService = injector.get(AuthService)
    const user = authService.getSessionUser()
    const token = authService.getSessionUserToken()
    if (user && token) {
      await permissionLoaderService.fetchAndLoadPermissions();
    }
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule.forRoot(getApiConfig),
    // since angular 6 we need to put in the root of the app to be able to use tooltip, datepicker, autocomplete...
    OverlayModule,

    FontAwesomeModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPermissionsModule.forRoot(),
    ToastModule
  ],
  bootstrap: [AppComponent],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OnlineHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializePermissions,
      deps: [
        Injector,
        PermissionLoaderService
      ],
      multi: true
    }
  ]
})
export class AppModule {
  constructor() {
  }
}
