/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { IMongoId } from '../model/mongo-object';
import { AlertConfig } from '../model/alertConfig';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';

@Injectable()
export class AlertConfigService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/alert-configs`, params)
  }

  public create(notificationConfig: AlertConfig): Observable<AlertConfig> {
    this.fieldsToOmit = ['_id', 'created_at', 'updated_at'];
    return this.sendPOST(`${this.basePath}/alert-configs`, notificationConfig)
  }

  public show(id: string): Observable<AlertConfig> {
    return this.sendGET(`${this.basePath}/alert-configs/${id}`)
  }

  public replace(id: string, alertConfig: AlertConfig) {
    this.fieldsToOmit = ['_id', 'created_at', 'updated_at'];
    return this.sendPUT(`${this.basePath}/alert-configs/${id}`, alertConfig);
  }

  public remove(id: string) {
    return this.sendDELETE(`${this.basePath}/alert-configs/${id}`);
  }

  public replaceMailConnectors(id: string, mailConnectorIds: Array<IMongoId>) {
    this.fieldsToOmit = ['created_at', 'updated_at'];
    return this.sendPUT(`${this.basePath}/alert-configs/${id}/mail-connectors`, mailConnectorIds);
  }

  public showMailConnectors(id: string, params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/alert-configs/${id}/mail-connectors`, params)
  }
}
