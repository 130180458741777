/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Setting } from '../model/setting';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class SettingService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }
  public get(): Observable<Setting> {
    return this.sendGET(`${this.basePath}/settings`)
  }

  public update(setting: Setting): Observable<Setting> {
    return this.sendPATCH(`${this.basePath}/settings`, setting)
  }
}
