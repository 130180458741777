import * as moment from 'moment-timezone';
import { isNil } from 'lodash-es';

export interface LightCommand {
  reason?: string;
  expiration?: Date;
  state: {
    name: string;
    value: number;
  }
}

/**
 * Generic interface, not exported.
 * It is extended by TargetCommandSpa or TargetCommandDto.
 */
interface TargetCommand {
  reason: string;
  state: CommandState;
}

/**
 * TargetCommand with expiration as Date object.
 */
export interface TargetCommandSpa extends TargetCommand {
  expiration: Date;
}

/**
 * TargetCommand with expiration date as ISO string.
 */
export interface TargetCommandDto extends TargetCommand {
  expiration: string | Date;
}

/**
 * CommandState from TALQ.
 */
export interface CommandState {
  name: string;
  value: number;
}

/**
 * Returns the override command payload the CMS will send to gateway
 * regarding the device itself or a group of device
 * and takes care of
 *   - expiration date expressed in Zulu time but keeping user target date
 *   - if no date is given then the override command will be infinite
 *
 * @see file src/app/admin/device/editor/device/light-command/light-command.component.ts#sendLightCommand
 * @see file src/app/admin/group/editor/light-command/light-command.component.ts#purposeCommand
 * @param targetExpiration - desired date of expiration
 * @param levelState - desired level of light expressed in percentage
 */
export function overrideCommand(targetExpiration, levelState: number): LightCommand {
  let expiration = isNil(targetExpiration) ? undefined : targetExpiration;

  if (expiration !== undefined) {
    // update expiration and remove information about timezone
    // we just keep the date as what user inputs and transform it to Zulu
    // on backend
    expiration = moment(expiration)
      .utc(true)
      .toISOString();
  }

  return {
    // primeng will return null if there is no date, to omit this value we just set
    // it to undefined so that it won't be passed to the backend
    expiration,
    state: {
      name: 'LevelState',
      value: levelState
    }
  }
}

/**
 * Converts the light command "expiration" property from ISO string to Date.
 * Modifies the command object.
 * @param command
 */
export function commandExpToDate(command: TargetCommandDto) {
  if (command.expiration && typeof command.expiration === 'string' && moment(command.expiration).isValid()) {
    command.expiration = moment(command.expiration).toDate();
  } else {
    throw Error('[light-command.builder::commandExpToDate] Invalid expiration date string ' + command.expiration)
  }
}
