/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Dictionary } from '../model/dictionary';
import { IPaginationParams } from '../../../interfaces/pagination.interfaces';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';

import { omitBy, isNil } from 'lodash-es'


@Injectable()
export class AppLogService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Retrieves AppLog entities based on API query params.
   * @param filter - Filter parameter, e.g { propertyA: value, propertyB: value }
   * @param select - Projection parameter, e.g 'propertyA,propertyB'
   * @param sort - Sort parameter, e.g. 'propertyA,-propertyB'
   * @param pagination - Pagination parameter, e.g. { page: 1, limit: 50 }
   */
  public index(
    filter?: Dictionary<any>,
    select?: string,
    sort?: string,
    pagination?: IPaginationParams
  ): Observable<any> {
    const params = omitBy({ ...filter, fields: select, sort, ...pagination }, isNil)
    return this.sendGETPaginated(`${this.basePath}/app-logs`, params)
  }

  /**
   * Retrieves all distinct exception names recorded in the CMS logs.
   * @returns {Observable<Array<String>>}
   */
  public getExceptionNames(): Observable<Array<String>> {
    return this.sendGET(`${this.basePath}/app-logs/exception-names`)
  }
}
