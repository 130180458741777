/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { set, has, isNil } from 'lodash-es';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Permission } from '../model/rbac/permission';
import { Role } from '../model/rbac/role';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class RoleService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  // protected fieldsToOmit = this.fieldsToOmit.filter(p => p !== '_id')

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
    this.fieldsToOmit = this.fieldsToOmit.filter(p => p !== '_id')
  }

  public index(params?: any): Observable<Array<Role>> {
    if (has(params, 'priority') && !isNil(params.priority)) {
      set(params, 'priority<', params.priority);
      delete params.priority
    }

    return this.sendGET(`${this.basePath}/roles`, params)
  }

  public showPermissions(roleId: string, params?: any): Observable<Array<Permission>> {
    return this.sendGET(`${this.basePath}/roles/${roleId}/permissions`, params)
  }

  public replacePermissions(roleId: string, permissionIds: { _id: string }[]): Observable<Array<Permission>> {
    this.throwErrorIfParamsIsNotProvided({ roleId, permissionIds }, 'replacePermissions')
    return this.sendPUT(`${this.basePath}/roles/${roleId}/permissions`, permissionIds)
  }

  public show(roleId: string): Observable<Role> {
    return this.sendGET(`${this.basePath}/roles/${roleId}`)
  }

  public update(roleId: string, role: Role): Observable<Role> {
    return this.sendPATCH(`${this.basePath}/roles/${roleId}`, role)
  }

  public store(role: Role): Observable<Role> {
    return this.sendPOST(`${this.basePath}/roles`, role)
  }

  public destroy(roleId: string): Observable<Role> {
    return this.sendDELETE(`${this.basePath}/roles/${roleId}`)
  }
}
