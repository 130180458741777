import { HttpHandler } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '../../../environments/environment';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Accept-version', environment.apiVersion)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
