import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { LoggerConfig } from '../model/logger-config';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';

@Injectable()
export class LoggerConfigService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Retrieve the list of logger config stored on CMS
   * TODO use proper Observable return type for this paginated endpoint
   */
  public indexWithDevicesCount(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/logger-configs-with-devices-count`, params);
  }

  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/logger-configs`, params);
  }

  public show(address: string): Observable<LoggerConfig> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'show');
    return this.sendGET(`${this.basePath}/logger-configs/${address}`);
  }

  public delete(address: string): Observable<LoggerConfig> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'delete');
    return this.sendDELETE(`${this.basePath}/logger-configs/${address}`);
  }
}

