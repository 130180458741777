import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { AlertPatch } from '../model/alert';
import { Alert } from '../model/alert';
import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class AlertService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/alerts`, params)
  }

  /**
   * Returns the alert identified by
   * @param id - mongo id string
   */
  public show(id: string): Observable<Alert> {
    return this.sendGET(`${this.basePath}/alerts/${id}`)
  }

  /**
   * Updates an Alert.
   * alertPatch are a subset or all of the properties of Alert model.
   * @param id
   * @param alertPatch
   */
  public update(id: string, alertPatch: AlertPatch): Observable<Alert> {
    return this.sendPATCH(`${this.basePath}/alerts/${id}`, alertPatch)
  }
}
