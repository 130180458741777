/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Command } from '../model/command';
import { EntityResource } from '../model/entity-resource';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class LightControlService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Sends an assign command to the gateway(s) if multiple devices are selected with
   * the purpose of canceling all previous assign command. Resume the normal behaviour
   * @param deviceAddresses - talq address list of the devices that will receive the command
   */
  public cancelAssignCommand(deviceAddresses: Array<string>) {
    this.throwErrorIfParamsIsNotProvided({ deviceAddresses }, 'cancelAssignCommand');
    return this.sendPOST(`${this.basePath}/assign-command`, { deviceAddresses })
  }

  /**
   * Sends an assign command to the gateway(s) if multiple devices are selected
   * @param deviceAddresses - talq address list of the devices that will receive the command
   * @param entity - command that will be sent to the gateway
   */
  public sendAssignCommand(deviceAddresses: Array<string>, entity: EntityResource): Observable<string> {
    this.throwErrorIfParamsIsNotProvided({ deviceAddresses, entity }, 'sendAssignCommand');
    return this.sendPOST(`${this.basePath}/assign-command`, { deviceAddresses, entity });
  }

  /**
   * Sends an override to the gateway(s) if multiple devices are selected
   * @param deviceAddresses - talq address list of the devices that will receive the command
   * @param command - command that will be sent to the gateway
   */
  public sendDeviceOverride(deviceAddresses: Array<string>, command: Command): Observable<string> {
    this.throwErrorIfParamsIsNotProvided({ deviceAddresses, command }, 'sendDeviceOverride');
    return this.sendPOST(`${this.basePath}/manual-override`, { deviceAddresses, command });
  }
}
