import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class OnDemandReadService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Asks the CMS backend to ask the gateway fresh values for the function
   * identified by
   *
   * Expects 204 http code on success
   *
   * @param address - TALQ address of the device
   * @param functionId - The function identifier
   */
  public onDemandReadFunctionId(address: string, functionId: string): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ address, functionId }, 'onDemandReadFunctionId');
    return this.sendGET(
      `${this.basePath}/on-demand-read/${address}/${functionId}`
    )
  }
}
