/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { LampType } from '../model/lampType';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class LampTypeService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Returns all the lampTypes present in the CMS
   */
  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/lamp-types`, params)
  }

  /**
   * Creates lampType on CMS
   * @param gatewayAddress
   * @param lampType
   */
  public create(gatewayAddress: string, lampType: LampType): Observable<LampType> {
    return this.sendPOST(`${this.basePath}/lamp-types`, { gatewayAddress, lampType })
  }

  /**
   * Returns the lampType identified by
   * @param address
   */
  public show(address: string): Observable<LampType> {
    return this.sendGET(`${this.basePath}/lamp-types/${address}`)
  }

  /**
   * Replaces a lampType on CMS
   * @param address
   * @param lampType
   */
  public replace(address: string, lampType: LampType) {
    return this.sendPUT(`${this.basePath}/lamp-types/${address}`, lampType);
  }

  /**
   * Deletes a lampType on CMS
   * @param address
   */
  public remove(address: string) {
    return this.sendDELETE(`${this.basePath}/lamp-types/${address}`);
  }
}
