import * as momentTZ from 'moment-timezone';

/**
 * The return the base moment timezone object, it can be manipulated as every base moment object
 * @param timezone
 * @param date
 */
const getTimezoneOject = (timezone: string, date: Date = new Date()) => {
  return momentTZ(date)
    .clone()
    .tz(timezone);
}

/**
 * Return the localtime as string for a given
 * @param timezone: String represent the timezone
 * @param date: Date, by default the current Date is applied
 */
export const getLocalTimeFor = (timezone: string, date: Date = new Date()): string => {
  return getTimezoneOject(timezone, date).format()
}

/**
 * Helper to retrieve the UTC offset given the timezone based on current date
 * @param timezone
 */
export const getUTCOffsetForTimezone = (timezone: string): number => {
  // The native javascript implementation return offsets in the other way
  // eg, for Zurich winter time, we have UTC+1 and the native javascript implementation returns -60
  // whereas the momentTZ return 60 for UTC+1
  return getTimezoneOject(timezone).tz(timezone).utcOffset();
}
