import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../providers/adonis/api/user.service';
import { Configuration } from '../../providers/adonis/configuration';
import { User } from '../../providers/adonis/model/user/user';
import { UserToken } from '../../providers/adonis/model/user/user-token';
import { PermissionLoaderService } from '../permission-loader.service';
import { SharedService } from '../shared-service';

const KEYS = {
  TOKEN: 'token',
  USER: 'user'
};


@Injectable({ providedIn: 'root' })
export class AuthService {

  authToken;
  user;
  options;
  isLoggingOut = false;

  constructor(
    private configuration: Configuration,
    private userService: UserService,
    private permissionLoaderService: PermissionLoaderService,
    private sharedService: SharedService,
    private router: Router
  ) {
  }

  public login(email: string, password: string): Promise<UserToken> {
    return new Promise(async (resolve, reject) => {
      this.userService
        .login(email, password)
        .subscribe({
          next: async (tokenData: UserToken) => {
            this.storeTokenData(tokenData);
            const user: User = await this.userService.show('me').toPromise();
            this.storeUserData(user);
            await this.permissionLoaderService.fetchAndLoadPermissions()
            this.isLoggingOut = false;
            resolve(tokenData);
          },
          error: err => {
            console.error('AuthService > Cannot retrieve user or store user', err);
            reject(err);
          }
        });
    });
  }

  public logout() {
    // checks if we are trying to logout, if it's not the case, then call the endpoint
    if (!this.isLoggingOut) {
      this.isLoggingOut = true;
      this.preLogout().subscribe({ next: () => this.postLogout() });
    } else {
      // otherwise just do the postLogout behaviour
      this.postLogout();
    }
  }

  /**
   * Will navigate to user profile
   * If the route contains the url of a user edition then it will replace the URL so that
   * the navigation tree will redirect to user list instead of previous user edition
   * Otherwise, will just pop back the navigation tree
   */
  public seeUserProfile() {
    const currentUrl = this.router.url;
    const userEditionUrl = '/admin/users/';
    const hasToReplace = currentUrl.indexOf(userEditionUrl) > -1;

    this.router.navigate(['/admin/users/me'], { replaceUrl: hasToReplace });
  }

  // will return true only if there is a registered token and a valid in time token
  public loggedIn() {
    const tokenString = localStorage.getItem(KEYS.TOKEN);
    const isLoggedIn = !!tokenString;
    if (isLoggedIn && !this.configuration.apiKeys.Authorization) {
      const userToken = JSON.parse(tokenString);
      this.configuration.apiKeys.Authorization = `${userToken.type} ${userToken.token}`;
    }
    return isLoggedIn;
  }

  public getSessionUser(): User {
    return JSON.parse(localStorage.getItem(KEYS.USER));
  }

  public getSessionUserToken(): string | null {
    const tokenString = localStorage.getItem(KEYS.TOKEN);

    try {
      const tokenObj = JSON.parse(tokenString);
      return `${tokenObj.type} ${tokenObj.token}`;
    } catch (jsonParseException) {
      return null;
    }
  }

  public getToken(): UserToken | null {
    return JSON.parse(localStorage.getItem(KEYS.TOKEN));
  }

  public clearData() {
    localStorage.clear();
  }

  private storeTokenData(token: UserToken) {
    this.authToken = token;
    localStorage.setItem(KEYS.TOKEN, JSON.stringify(token));
  }

  private storeUserData(user) {
    this.user = user;
    localStorage.setItem(KEYS.USER, JSON.stringify(user));
  }

  private preLogout(): Observable<boolean> {
    return this.userService.logout();
  }

  private postLogout() {
    this.authToken = null;
    this.user = null;
    this.clearData();
    this.configuration.apiKeys = {};
    this.permissionLoaderService.flushPermissions()
    return this.router.navigate(['/user/sign-in'], { replaceUrl: true });
  }
}
