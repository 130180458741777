import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { GroupAdapter } from '../model/group';
import { GroupDto } from '../model/group';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class GroupService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private groupAdapter: GroupAdapter
  ) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Get devices for a group
   * @param address
   * @param params
   */
  public showDevices(address: string, params?: any): Observable<any> {
    return this.sendGET(`${this.basePath}/groups/${address}/devices`, params);
  }

  /**
   * Lists all groups (TALQ and CMS) with devices count.
   * @param params
   */
  public indexGroupsWithDevicesCount(params?: any) {
    return this.sendGETPaginated(`${this.basePath}/groups-with-devices-count`, params);
  }

  /**
   * Shows a group with purpose CMS.
   * @param address
   */
  public show(address: string): Observable<GroupDto> {
    return this.sendGET(`${this.basePath}/groups/${address}`);
  }

  /**
   * Stores a group with purpose CMS.
   * @param group
   */
  public store(group: GroupDto): Observable<GroupDto> {
    return this.sendPOST(`${this.basePath}/groups/`, this.groupAdapter.removeReadOnly(group));
  }

  /**
   * Replaces a group with purpose CMS.
   * @param address
   * @param group
   */
  public replace(address: string, group: GroupDto) {
    return this.sendPUT(`${this.basePath}/groups/${address}`, this.groupAdapter.removeReadOnly(group));
  }

  /**
   * Deletes a group with purpose CMS.
   * @param address
   */
  public destroy(address: string) {
    return this.sendDELETE(`${this.basePath}/groups/${address}`);
  }

  /**
   * Replace device addresses for a CMS group
   * @param address
   * @param addresses
   */
  public replaceDevicesCMSGroup(address: string, addresses: Array<string>): Observable<Array<string>> {
    return this.sendPUT(`${this.basePath}/groups/${address}/devices`, addresses);
  }
}
