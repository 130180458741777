/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Calendar } from '../model/calendar';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class CalendarService extends ApiService {

  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Returns all the calendars present in the CMS
   */
  index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/calendars`, params)
  }

  /**
   * Creates calendar for a given gateway and device class
   * @param gatewayAddress
   * @param deviceClassName
   * @param payload
   */
  createGatewayDeviceClass(gatewayAddress: string, deviceClassName: string, payload: Calendar): Observable<Calendar> {
    this.throwErrorIfParamsIsNotProvided({ gatewayAddress, deviceClassName, payload }, 'createGatewayDeviceClass');
    return this.sendPOST(`${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/calendars`, payload)
  }

  /**
   * Gets the active program schedule for calendar
   * @param calendar
   * @param year
   */
  getActiveProgramsScheduleWithCalendar(calendar: Calendar, year: number) {
    this.throwErrorIfParamsIsNotProvided({ calendar, year }, 'getActiveProgramsScheduleWithCalendar')
    return this.sendPOST(`${this.basePath}/calendars/generate-active-programs-schedule`,
      { calendar, year }
    );
  }

  /**
   * Gets active program for calendar
   * @param id
   * @param date
   */
  getActiveProgram(id: string, date: string) {
    this.throwErrorIfParamsIsNotProvided({ id, date }, 'getActiveProgram');
    return this.sendGET(
      `${this.basePath}/calendars/${encodeURIComponent(String(id))}/active-programs/${encodeURIComponent(String(date))}`
    );
  }

  /**
   * Returns the calendar identified by
   * @param id
   */
  show(id: string): Observable<Calendar> {
    return this.sendGET(`${this.basePath}/calendars/${id}`)
  }

  /**
   * Replaces a calendar for a given gateway and device class
   * @param gatewayAddress
   * @param deviceClassName
   * @param id
   * @param payload
   */
  replaceGatewayDeviceClass(gatewayAddress: string, deviceClassName: string, id: string, payload: Calendar) {
    this.throwErrorIfParamsIsNotProvided({ gatewayAddress, deviceClassName, id, payload }, 'replaceGatewayDeviceClass');
    return this.sendPUT(`${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/calendars/${id}`, payload);
  }

  /**
   * Deletes a calendar on CMS
   * @param id
   */
  remove(id: string) {
    return this.sendDELETE(`${this.basePath}/calendars/${id}`);
  }
}
