import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';
import * as MomentTZ from 'moment-timezone';
import { Observable } from 'rxjs/internal/Observable';
import * as tzLookup from 'tz-lookup';
import { OSMPlace } from '../../interfaces/open-street-map.interfaces';
import { getUTCOffsetForTimezone } from './timezone.helper';

const OSM_SEARCH_API = 'https://nominatim.openstreetmap.org/search';
const ZULU_TIMEZONE = 'Etc/UTC';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  // just to display the first point on map
  private _guessedTimeZone: string;

  public static get ZULU_TIMEZONE() {
    return ZULU_TIMEZONE;
  }

  public static get TimezoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined) {
        if (MomentTZ.tz.zone(control.value) === null) {
          return { 'timezone': true };
        }
      }
      return null;
    };
  }

  constructor(private http: HttpClient) {
    this.initTimeZones();
  }

  initTimeZones() {
    this._guessedTimeZone = MomentTZ.tz.guess();
  }

  /**
   * Produces a date in the browser's timezone without changing the wall time of the given date.
   * Even if the given date's timezone and the browser's timezone are different.
   * If a timezone argument is provided the date in input will be converted to the timezone
   * to preserve that timezone's wall time.
   * @param dateTime
   * @param timezone
   */
  convertToBrowserTimeZonePreservingWallTime(dateTime: string | Date, timezone?: string) {
    let date = MomentTZ(dateTime);
    // Sets the desired timezone's wall time if any
    if (timezone) {
      date = date.tz(timezone);
    }
    const browserTimezone = this._guessedTimeZone;
    // Change to Browser's timezone without changing the wall time
    return date.tz(browserTimezone, true).toDate()
  }

  public getBrowserTimezone() {
    return this._guessedTimeZone;
  }

  /**
   * Removes the timezone offset from a javascript date object and
   * returns the date-time string in following format YYYY-MM-DD HH:mm:ss.
   * Truncates the date to the precision of seconds.
   * @param date
   */
  public removeTimeZone(date: Date): string {
    if (!this.isValidDate(date)) {
      console.error(`[TimezoneService::removeTimeZone] Invalid date: ${date.toString()}`);
      return date.toString();
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    let monthString;
    // add leading zeros
    month < 10 ? monthString = '0' + month : monthString = month;
    const day = date.getDate();
    let dayString;
    day < 10 ? dayString = '0' + day : dayString = day;
    const hour = date.getHours();
    let hourString;
    // add leading zeros
    hour < 10 ? hourString = '0' + hour : hourString = hour;
    const minute = date.getMinutes();
    let minuteString;
    // add leading zeros
    minute < 10 ? minuteString = '0' + minute : minuteString = minute;
    const second = date.getSeconds();
    let secondString;
    // add leading zeros
    second < 10 ? secondString = '0' + second : secondString = second;
    return `${year}-${monthString}-${dayString} ${hourString}:${minuteString}:${secondString}`;
  }

  /**
   * Takes only the date-time from the date object, sets the new timezone and converts the obtained date-time
   * to a string representation of zulu time YYYY-MM-DDTHH:mm:ss[Z].
   * Truncates the date to the precision of seconds.
   * @param date
   * @param timezone
   */
  public replaceTimeZone(date: Date, timezone: string): string {
    // remove current TZ and format the string as YYYY-MM-DD HH:mm (removes seconds and fractional seconds)
    const dateWithoutTZ = this.removeTimeZone(date);
    const dateInTZ =
      MomentTZ
        .tz(dateWithoutTZ, timezone.trim()) // create the date-time string in required TZ
        .utc() // convert the new date-time in zulu
    if (!dateInTZ.isValid()) {
      console.error(`[TimezoneService::replaceTimeZone] Invalid date or timezone: ${date} ${timezone}`)
    }
    // return the converted date-time in zulu
    return dateInTZ.format('YYYY-MM-DDTHH:mm:ss[Z]')
  }

  /**
   * Validates if the parameter is a valid javascript date object.
   * @example date = new Date('2020-08-01') true
   * @example date = new Date('invalid') false
   * @param date
   */
  public isValidDate(date: Date): boolean {
    return (date instanceof Date && !isNaN(date.valueOf()));
  }

  /**
   * Returns the closest timezone based on latitude and longitude
   * @see https://www.npmjs.com/package/tz-lookup
   * @param lat
   * @param lon
   */
  public retrieveTimezoneFromGeo(lat: number | string, lon: number | string): string {
    return tzLookup(lat, lon);
  }

  public localGMTOffsetInHours(lat, lon) {
    const timezone = this.retrieveTimezoneFromGeo(lat, lon);
    const offset = getUTCOffsetForTimezone(timezone) / 60;
    return offset >= 0 ?
      `+${offset}` : `${offset}`;
  }

  public localGMTOffset(lat, lon) {
    const timezone = this.retrieveTimezoneFromGeo(lat, lon);
    return getUTCOffsetForTimezone(timezone);
  }

  public localTimeFor(lat, lon) {
    const timezone = this.retrieveTimezoneFromGeo(lat, lon);
    return MomentTZ(new Date()).clone().tz(timezone).format('HH:mm');
  }

  getDatePipeOffsetFormat(offset) {
    return ((offset > 0 ? '+' : '-') + this.addZero(Math.abs(offset / 60), 2) + this.addZero(Math.abs(offset % 60), 2));
  }

  // we can use OSM for free without registration
  public getOSMPlaceFor(toSearch: string, multi: boolean): Observable<Array<OSMPlace>> {
    const headers = new HttpHeaders();
    headers.set('Access-Control-Allow-Origin', '*');
    return this.http.get<Array<OSMPlace>>(this.buildPlaceQuery(toSearch, multi), { headers });
  }

  private addZero(number, length) {
    let str = '' + number
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

  // OSM stands for Open Street Map since geocode api is now paid for google api

  private buildPlaceQuery(toSearch: string, multi: boolean) {
    const params = {
      format: 'json',
      addressdetails: 1,
      extratags: 1,
      limit: multi ? 10 : 1
    };

    const query = Object.keys(params)
      .map(key => encodeURI(`${key}=${params[key]}`))
      .reduce((acc, val) => `${val}&${acc}`, '');

    return `${OSM_SEARCH_API}/${encodeURI(toSearch)}?${encodeURI(query.substring(0, query.length - 1))}`;
  }
}
