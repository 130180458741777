import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This implementation of TranslateLoader allows to use multiple files to
 * manage translations
 *
 * Thus we can
 *  - use static translations (known labels such as CMS settings page)
 *  - use dynamic translations (generated from the device class)
 *
 * The configuration of the resource paths are given in app.module.ts
 */
export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: { prefix: string, suffix: string }[] = [{
      prefix: '/assets/i18n/',
      suffix: '.json'
    }]) {
  }

  /**
   * Gets the translations from the server
   * @override
   * @param lang
   */
  public getTranslation(lang: string): Observable<Object> {
    // Process all translations from the instantiation in app.module.ts
    return forkJoin(
      this.resources.map(config => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`);
      })
    )
      // Returns a single object representing the full translation
      .pipe(map(allTranslations => {
        return allTranslations
          .reduce((a, b) => {
            return Object.assign(a, b);
          });
      }));
  }
}
