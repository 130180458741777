/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { ScopedClass } from '../../../interfaces/scoped-class.interface';
import { Configuration } from '../configuration';
import { Device } from '../model/device';
import { DeviceClass } from '../model/device-class';
import { LoggerConfig } from '../model/logger-config';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class DeviceService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Returns all the devices present in the CMS
   */
  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/devices`, params)
  }

  /**
   * Returns all the devices present in the CMS with picked properties
   */
  public indexWithEntities(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/devices-with-entities`, params)
  }

  /**
   * Returns the device identified by
   * @param address - mongo id of the device
   */
  public show(address: string): Observable<Device> {
    return this.sendGET(`${this.basePath}/devices/${address}`)
  }

  public update(id: string, body: Device) {
    return this.sendPATCH(`${this.basePath}/devices/${id}`, body);
  }

  /**
   * Return the device class for the device identified by
   * @param id - mongo id of the device
   */
  public getDeviceClass(id: string): Observable<DeviceClass> {
    this.throwErrorIfParamsIsNotProvided({ id }, 'getDeviceClass')
    return this.sendGET(`${this.basePath}/devices/${id}/device-class`)
  }

  public getScopedDeviceClass(id: string): Observable<ScopedClass> {
    this.throwErrorIfParamsIsNotProvided({ id }, 'getScopedDeviceClass')
    return this.sendGET(`${this.basePath}/devices/${id}/device-class/scoped`)
  }

  public getLoggerConfigs(address: string): Observable<Array<LoggerConfig>> {
    this.throwErrorIfParamsIsNotProvided({ address }, 'getLoggerConfigs');
    return this.sendGET(`${this.basePath}/devices/${address}/logger-configs`)
  }

  /**
   * Attach a logger config to the target device
   * @param {string} address - device address
   * @param {string} loggerAddress - logger config address
   */
  public addLoggerConfig(address: string, loggerAddress: string): Observable<LoggerConfig> {
    this.throwErrorIfParamsIsNotProvided({ address, loggerAddress }, 'addLoggerConfig');
    return this.sendPOST(`${this.basePath}/devices/${address}/logger-configs`, { address: loggerAddress })
  }

  /**
   * Detach a logger config from the target device
   * @param {string} address - device address
   * @param {string} loggerAddress - logger config address
   */
  public removeLoggerConfig(address: string, loggerAddress: string): Observable<boolean> {
    this.throwErrorIfParamsIsNotProvided({ address, loggerAddress }, 'removeLoggerConfig');
    return this.sendDELETE(`${this.basePath}/devices/${address}/logger-configs/${loggerAddress}`)
  }

  /**
   * Replace all logger configs of the target device
   * @param {string} address - device address
   * @param {Array<string>}loggerAddresses - logger config addresses
   */
  public replaceLoggerConfigs(address: string, loggerAddresses: Array<string>): Observable<Array<LoggerConfig>> {
    this.throwErrorIfParamsIsNotProvided({ address, loggerAddresses }, 'replaceLoggerConfigs');
    return this.sendPUT(
      `${this.basePath}/devices/${address}/logger-configs`,
      loggerAddresses.map(loggerAddress => ({ address: loggerAddress }))
    );
  }
}
