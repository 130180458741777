import { HttpErrorResponse } from '@angular/common/http';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { AuthService } from '../../services/authService/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const userToken = this.auth.getSessionUserToken();
    // Clone the request to add the new header.
    const authReq = !isNil(userToken) ?
      req.clone({ headers: req.headers.set('Authorization', userToken) }) :
      req.clone();

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // Redirect to login page clearing all current user data
          // For now there is refresh token
          this.router
            .navigate(['/user/sign-in'], { replaceUrl: true })
            .then(() => this.auth.clearData());
        }

        return throwError(error);
      })
    )
  }
}
