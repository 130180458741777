/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { TimezoneService } from '../../../services/timezone/timezone.service';
import { Configuration } from '../configuration';
import { DeviceLog } from '../model/deviceLog';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';

@Injectable()
export class DataLogService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private timezoneService: TimezoneService
  ) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Get logs by attributes for raw charts
   * @param device_address
   * @param name - attributes name like 'activePower,activeEnergy'
   * @param start
   * @param end
   * @param timezone
   */
  public getRawDataLogs({ device_address, name, start, end, timezone = TimezoneService.ZULU_TIMEZONE }): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ device_address, name, start, end }, 'getRawDataLogs')
    const params = { device_address, name, 'timestamp>': start, 'timestamp<': end, sort: 'timestamp', timezone };

    return this.sendGET(`${this.basePath}/attribute-logs/raw`, params)
  }

  /**
   * Get logs by attributes for average charts
   * @param device_address
   * @param name
   * @param start
   * @param end
   * @param periods
   * @param timezone
   */
  public getAvgDataLogs({ device_address, name, start, end, periods, timezone = TimezoneService.ZULU_TIMEZONE }):
    Observable<Array<DeviceLog>> {
    this.throwErrorIfParamsIsNotProvided({ device_address, name, start, end }, 'getAvgDataLogs')
    const params = {
      device_address,
      name,
      'timestamp>': start,
      'timestamp<': end,
      periods,
      sort: 'timestamp'
    };

    return this.sendGET(`${this.basePath}/attribute-logs/avg`, params)
  }

  public getAvgMovCountLogs({ start, end, periods }): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ start, end }, 'getAvgDataLogs')
    const params = { 'timestamp>': start.toISOString(), 'timestamp<': end.toISOString(), periods, sort: 'timestamp' };

    return this.sendGET(`${this.basePath}/attribute-logs/avg-mov-count`, params)
  }

  /**
   * Get average attribute logs grouped by period
   * @param device_address
   * @param name
   * @param start
   * @param end
   * @param periods
   * @param timezone
   */
  public getAvgDataLogsByPeriod({ device_address, name, start, end, periods, timezone = TimezoneService.ZULU_TIMEZONE }): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ device_address, name, start, end, timezone }, 'getAvgDataLogsByPeriod')
    const params = {
      device_address,
      name,
      'timestamp>': start,
      'timestamp<': end,
      periods,
      sort: 'timestamp'
    };

    return this.sendGET(`${this.basePath}/attribute-logs/avg-by-period`, params)
  }

  /**
   * Get raw attribute logs grouped by period
   * @param device_address
   * @param name
   * @param start
   * @param end
   * @param periods
   * @param timezone
   */
  public getRawDataLogsByPeriod({ device_address, name, start, end, timezone = TimezoneService.ZULU_TIMEZONE }): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ device_address, name, start, end, timezone }, 'getRawDataLogsByPeriod')
    const params = { device_address, name, 'timestamp>': start, 'timestamp<': end, sort: 'timestamp', };

    return this.sendGET(`${this.basePath}/attribute-logs/raw-by-period`, params)
  }

  /**
   * Get excel file of raw data
   * @param filter : {device_address, name <attributes>, start, end}
   * @param columns : header of columns with translations for the excel file
   */
  public downloadRawLogs(filter, columns): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ filter, columns }, 'downloadRawLogs')
    return this.sendPOST(`${this.basePath}/attribute-logs/raw/downloads`, { filter, columns })
  }

  /**
   * Get excel file of average data
   * @param filter : {device_address<Array>, name <Array<attributes>>, start, end, periods}
   * @param columns : header of columns with translations for the excel file
   */
  public downloadAverageLogs(filter, columns): Observable<any> {
    this.throwErrorIfParamsIsNotProvided({ filter, columns }, 'downloadAverageLogs')
    return this.sendPOST(`${this.basePath}/attribute-logs/avg/downloads`, { filter, columns })
  }
}
