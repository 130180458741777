/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { ControlProgram } from '../model/controlProgram';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class ControlProgramService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Returns all the programs present in the CMS
   */
  public index(params?: any): Observable<any> {
    return this.sendGETPaginated(`${this.basePath}/control-programs`, params)
  }

  /**
   * Returns the control program identified by
   * @param id
   */
  public show(id: string): Observable<ControlProgram> {
    return this.sendGET(`${this.basePath}/control-programs/${id}`)
  }

  /**
   * Returns the count of the control programs
   */
  public countControlPrograms(): Observable<number> {
    return this.sendGET(`${this.basePath}/control-programs/count`)
  }

  /**
   * Deletes a control program on CMS
   * @param id
   */
  public remove(id: string): Observable<boolean> {
    return this.sendDELETE(`${this.basePath}/control-programs/${id}`);
  }

  /**
   * Creates a control program for a given gateway and device class pair
   * @param gatewayAddress
   * @param deviceClassName
   * @param payload
   */
  public createGatewayDeviceClass(gatewayAddress: string, deviceClassName: string, payload: ControlProgram): Observable<any> {
    this.throwErrorIfParamsIsNotProvided(
      { gatewayAddress, deviceClassName, payload }, 'createGatewayDeviceClass'
    );
    return this.sendPOST(
      `${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/control-programs`, payload
    );
  }

  /**
   * Replaces a control program for a given gateway and device class pair
   * @param gatewayAddress
   * @param id
   * @param deviceClassName
   * @param payload
   */
  public replaceGatewayDeviceClass(
    gatewayAddress: string, deviceClassName: string, id: string, payload: ControlProgram
  ): Observable<ControlProgram> {
    this.throwErrorIfParamsIsNotProvided(
      { gatewayAddress, deviceClassName, id, payload }, 'replaceGatewayDeviceClass'
    );
    return this.sendPUT(
      `${this.basePath}/gateways/${gatewayAddress}/device-classes/${deviceClassName}/control-programs/${id}`, payload
    );
  }
}
