import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { FunctionTypeEvent } from '../model/alertConfig';
import { DeviceClass } from '../model/device-class';
import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class DeviceClassService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Retrieves all the device classes present on the CMS
   * Can be filtered by optional parameters
   *
   * @param params
   */
  public index(params?: any): Observable<Array<DeviceClass>> {
    return this.sendGET(`${this.basePath}/device-classes`, params);
  }

  /**
   * Retrieves unique attribute names among all device classes filtered by the provided attribute scope.
   * Attribute scopes: "configuration", "event", "measurement".
   * @param attributeScope
   */
  public uniqueAttributes(attributeScope: string): Observable<Array<string>> {
    return this.sendGET(`${this.basePath}/device-classes/unique-attributes/${attributeScope}`)
  }

  /**
   * Retrieves unique pairs of attribute names and function types among all device classes filtered by the provided attribute scope.
   * Attribute scopes: "configuration", "event", "measurement".
   * @param attributeScope
   */
  public uniqueFunctionTypesAttributes(attributeScope: string): Observable<Array<FunctionTypeEvent>> {
    return this.sendGET(`${this.basePath}/device-classes/unique-function-types-attributes/${attributeScope}`)
  }
}
