import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

/**
 * Allows the date pipes to use other locale for date representations
 * @see src/app/admin/logger-config/list/logger-config-list.component.ts#transformDate
 */
registerLocaleData(localeFr);
registerLocaleData(localeRu);

@Component({
  selector: 'app-main',
  template: `
    <router-outlet></router-outlet>
    <!-- The toast is being shared by all direct children modules, so no need to redeclare them -->
    <p-toast key="toast" [baseZIndex]="10000"></p-toast>
  `
})
export class AppComponent implements OnInit {

  public constructor(
    private titleService: Title,
    private translateService: TranslateService
  ) {
    translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.titleService.setTitle(environment.appName);
    const language = localStorage.getItem('language') || this.translateService.getBrowserLang();
    this.translateService.use(language);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('language', event.lang)
    });
  }
}
