/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { MailConnector } from '../model/mail-connector';

import { BASE_PATH } from '../variables';
import { ApiService } from './api.service';


@Injectable()
export class MailConnectorService extends ApiService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

  /**
   * Retrieves all mail connectors stored on CMS
   */
  public index(): Observable<Array<MailConnector>> {
    return this.sendGET(`${this.basePath}/mail-connectors`);
  }

  /**
   * Retrieve the mail connector identified by its mongo id
   * @param id - {string} mongo id of the mail connector to retrieve
   */
  public show(id: string): Observable<MailConnector> {
    return this.sendGET(`${this.basePath}/mail-connectors/${id}`);
  }

  /**
   * Will update a given mail connector identified by its mongo id
   * @param id - {string} mongo id of the mail connector to update
   * @param data - {MailConnector} new properties for this mail connector
   */
  public replace(id: string, data: MailConnector): Observable<MailConnector> {
    return this.sendPUT(`${this.basePath}/mail-connectors/${id}`, data);
  }

  /**
   * Will destroy a given mail connector identified by its mongo id
   * @param id - {string} mongo id of the mail connector to delete
   */
  public destroy(id: string): Observable<boolean> {
    return this.sendDELETE(`${this.basePath}/mail-connectors/${id}`);
  }

  /**
   * Will create a new mail connector based on
   * @param data - {MailConnector} properties that represents this mail connector
   */
  public create(data: MailConnector): Observable<MailConnector> {
    return this.sendPOST(`${this.basePath}/mail-connectors`, data);
  }
}
